
/* View in fullscreen */
export function requestFullscreen(elem, options = {})
{
    if (elem.requestFullscreen)
    {
        return elem.requestFullscreen();
    }
    if (elem.webkitRequestFullscreen)
    {
        return elem.webkitRequestFullscreen();
    }
    if (elem.msRequestFullscreen)
    {
        /* IE11 */
        return elem.msRequestFullscreen();
    }
    return Promise.reject('unsupported')
}

export function exitFullscreen()
{
    if (document.exitFullscreen)
    {
        return document.exitFullscreen();
    }
    if (document.webkitExitFullscreen)
    {

        return document.webkitExitFullscreen();
    }
    if (document.msExitFullscreen)
    {

        return document.msExitFullscreen();
    }
    return Promise.reject('unsupported')
}
