import {
    setupVideoBackgroundListeners,
    startVideoBackground,
    stopVideoBackground
} from "./video-background";

const doc = document

// import Splide from "@splidejs/splide";
// import {Intersection} from '@splidejs/splide-extension-intersection';
import {requestFullscreen} from "./fullscreen";
import loadCSSFile from "./loadCSSFile";

// const splide_css = import('@splidejs/splide/dist/css/splide-core.min.css');

const splide_css = loadCSSFile('https://cdn.jsdelivr.net/npm/@splidejs/splide@4.1.4/dist/css/splide.min.css');
const splide_module = import('https://cdn.jsdelivr.net/npm/@splidejs/splide@4.1.4/+esm');
const splide_intersection_module = import('https://cdn.jsdelivr.net/npm/@splidejs/splide-extension-intersection@0.2.0/+esm');
// const splide_css = import('https://cdn.jsdelivr.net/npm/@splidejs/splide@4.1.4/dist/css/splide-core.min.css');
const video_module = import('video-worker/src/video-worker')

const min_width = 375

async function setupReelSplides()
{
    const reels = Array.from(doc.querySelectorAll('.VideoReel:not(.splide)'))
    if (!reels.length)
    {
        return;
    }
    await splide_css;
    const {Splide} = await splide_module;
    const {Intersection} = await splide_intersection_module;
    console.log({Splide, Intersection});

    Array.from(doc.querySelectorAll('.VideoReel__track')).forEach(track => track.classList.add('splide__track'))
    Array.from(doc.querySelectorAll('.VideoReel__list')).forEach(track => track.classList.add('splide__list'))
    Array.from(doc.querySelectorAll('.VideoReel__slide')).forEach(track => track.classList.add('splide__slide'))
    Array.from(doc.querySelectorAll('video')).forEach(video => video.controls = false)

    reels.forEach(reel =>
    {
        // if (reel.querySelector('[data-src],[data-video]'))
        // {
        //
        //     lightGallery(reel, {
        //         selector: '[data-src],[data-video]',
        //         plugins: [lgVideo],
        //         videojs: true,
        //         videojsOptions: {}
        //     });
        // }

        const num_items = Array.from(reel.querySelectorAll('.VideoReel__slide')).length

        let breakpoints = {}/*
        let i = 21
        while (i--)
        {
            const bp = (min_width * i) - min_width / 2
            if (bp > 0)
            {
                breakpoints[bp] = {
                    perPage: i - 1,
                    perMove: 1,
                }
            }
        }
        */
        let i = 1;
        let max = Math.min(5, Math.max(1, num_items))

        while (++i <= max)
        {
            const bp = Math.round(min_width * i - min_width / 2)
            breakpoints[bp] = {
                perPage: i,
                perMove: 1
            }
            // console.log({i, bp})
        }

        // console.log({num_items, breakpoints})


        reel.classList.add('splide')

        const splide = new Splide(reel, {
            // ...(num_items > 5 ? {type: 'loop'} : {}),
            // type: 'loop',
            // focus: 'center',
            // trimSpace: false,

            pagination: false,
            arrows: true,
            // autoplay: true,
            interval: 3000,

            perMove: 1,
            i18n: {
                prev: 'Vorige video',
                next: 'Volgende video'
            },
            perPage: 1,
            mediaQuery: 'min',
            breakpoints
        })
        // setupVideoBackgroundListeners();
        splide.on('mounted', () =>
        {
            console.log(reel.id)
            setupVideoBackgroundListeners();

            let media_queries = [];
            i = num_items
            while (i++ < 5)
            {
                const bp = Math.round(min_width * i - min_width / 2)
                const m = media_queries.length === 0 ? ';max-width:1920px;margin-left:auto;margin-right:auto' : '';
                const query = `@media (min-width:${bp}px){ #${reel.id}{width:calc(${i - 1}/${i}*100%)${m} }`
                console.log({i, bp, query})
                media_queries.push(query)
            }
            if (media_queries.length)
            {
                const style = doc.createElement('style');
                style.innerHTML = media_queries.join('');
                doc.querySelector('head').appendChild(style)
                console.log({media_queries, style});
            }
        })
        splide.mount({Intersection})
    })
}

const videoreel_workers = {};

async function setupReelIframeButtons()
{
    const buttons = Array.from(doc.querySelectorAll('.VideoReel__button[data-oembed-url^="http"]:not(.VideoReel__button--done)'));
    if (!buttons.length)
    {
        return;
    }
    const {default: VideoWorker} = await video_module;
    buttons.forEach(button =>
    {
        button.classList.add('VideoReel__button--done');
        console.log({button});
        const url = button.dataset.oembedUrl;

        if (videoreel_workers.hasOwnProperty(url))
        {
            return;
        }
        const worker = new VideoWorker(url, {});

        if (!worker.isValid())
        {
            console.error('video url invalid', {url, worker})
            return;
        }
        videoreel_workers[url] = {worker};

        console.log({video: worker})

        worker.getVideo(iframe =>
        {
            videoreel_workers[url].iframe = iframe
            // iframe.style.display = 'none'
            iframe.style.position = 'fixed'

            iframe.style.top = '-100%'
            iframe.style.left = '-100%'
            iframe.style.width = '100%'
            iframe.style.height = '100%'
            // iframe.style.zIndex = 999999;

            const parent = iframe.parentNode;
            document.body.appendChild(iframe);
            parent.parentNode.removeChild(parent);
        });
    })
}


doc.addEventListener('DOMContentLoaded', setupReelSplides);

doc.addEventListener('DOMContentLoaded', setupReelIframeButtons);

doc.addEventListener('click', e =>
{
    // console.log(e);
    if (e.target.closest('.VideoReel__button--fullscreen'))
    {
        e.preventDefault();
        // console.log({videoreel_iframes});
        let video

        if (e.target.matches('[data-oembed-url^="http"]'))
        {
            const url = e.target.dataset.oembedUrl;
            const worker = videoreel_workers[url];
            // console.log({url, worker, videoreel_workers});
            if (worker && worker.worker && worker.iframe)
            {
                worker.iframe.style.display = 'block';
                worker.worker.play();
                requestFullscreen(worker.iframe);

                return;
            }
        }

        video = e.target.closest('.VideoReel__figure').querySelector('.VideoReel__video');
        // console.log({video})
        if (video)
        {
            // video.requestFullscreen();
            requestFullscreen(video);
        }
    }
})


doc.addEventListener('fullscreenchange', e =>
{
    // console.log('fullscreenchange', doc.fullscreenElement);
    if (!doc.fullscreenElement)
    {
        Object.values(videoreel_workers)
            .forEach(worker =>
            {
                if (worker.worker)
                {
                    worker.worker.pause();
                }
            })
    }
    const video = e.target.closest('.VideoReel__video')
    if (video)
    {
        if (doc.fullscreenElement)
        {
            startVideoBackground(e)
            video.muted = false
        } else
        {
            stopVideoBackground(e)
            video.muted = true
        }
    }
})

jQuery(function ($)
{
    // console.log('jQuery loaded');
    let $reels = $('section.VideoReel');

    $reels.magnificPopup({
        delegate: '[data-mfp-src]',
        mainClass: 'mfp-fade',
        gallery: {
            enabled: true
        },
        iframe: {
            markup: '<div class="mfp-iframe-scaler">' +
                '<button class="mfp-close" title="Sluiten (Escape)"><span class="sr-only">Sluiten (Escape)</span> </button>' +
                '<iframe class="mfp-iframe" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>' +
                '</div>',
            patterns: {
                youtube: {
                    index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                    id: function (url)
                    {
                        url = new URL(url);
                        return url.searchParams.get('v');
                    },

                    src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.

                }
            }
        }
    })
})
