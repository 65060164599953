const doc = document

const canPlayVideos = !!document.createElement('video').canPlayType;

export function setupCustomVideoControls()
{
    const containers = Array.from(doc.querySelectorAll('.video-with-custom-controls:not(.init)'))
    containers.forEach(container =>
    {
        container.classList.add('init')
        const video = container.querySelector('video')
        const iframe = container.querySelector('iframe')

        if (!iframe && !video)
        {
            return
        }
        if (video && !canPlayVideos)
        {
            return
        }

        const controls = doc.createElement('div')
        controls.classList.add('controls')
        container.appendChild(controls)

        const playButton = doc.createElement('button')
        controls.appendChild(playButton)
        playButton.classList = 'play'

        if (video)
        {
            video.controls = false

            playButton.addEventListener('click', () =>
            {
                if (video.paused || video.ended)
                {
                    video.play();
                    video.controls = true
                } else
                {
                    video.pause();
                    video.controls = false
                }
            })

            function updatePlayButton()
            {
                const paused = video.paused || video.ended
                container.classList.toggle('paused', paused)
                container.classList.toggle('playing', !paused)
                video.controls = !paused
            }

            updatePlayButton()

            video.addEventListener('play', updatePlayButton);
            video.addEventListener('pause', updatePlayButton);
            video.addEventListener('ended', updatePlayButton);
        }
    })
}

setupCustomVideoControls();