const doc = document


export function getVideoForEvent(e)
{
    return e.target.closest('.video-background').querySelector('video');
}

export function startVideoBackground(video)
{
    if (video)
    {
        video.addEventListener('canplay', () =>
        {
            let n = Number(video.dataset.startTime)
            if (isNaN(n))
            {
                n = 0
            }
            video.currentTime = n
        }, {once: true})
        video.play()
    }
}

export function startVideoBackgroundForEvent(e)
{
    startVideoBackground(getVideoForEvent(e))
}

export function stopVideoBackground(video)
{
    if (video)
    {
        video.pause();
        // resets the video to poster
        setTimeout(() => video.src = video.currentSrc, 50);
    }
}

export function stopVideoBackgroundForEvent(e)
{
    stopVideoBackground(getVideoForEvent(e))
}

export function setupVideoBackgroundListeners()
{
    Array.from(doc.querySelectorAll('.video-background:not(.video-background-init)'))
        .forEach(video_background =>
        {
            video_background.classList.add('video-background-init')
            video_background.addEventListener('mouseenter', startVideoBackgroundForEvent)
            video_background.addEventListener('mouseleave', stopVideoBackgroundForEvent)

            video_background.addEventListener('focus', startVideoBackgroundForEvent, true)
            video_background.addEventListener('blur', stopVideoBackgroundForEvent, true)

            video_background.addEventListener('touchstart', startVideoBackgroundForEvent, {passive: true})
            video_background.addEventListener('touchend', stopVideoBackgroundForEvent, {passive: true})
        })
}

// setupListeners();
doc.addEventListener('DOMContentLoaded', setupVideoBackgroundListeners)