const doc = document

const setup = async () =>
{
    Array.from(doc.querySelectorAll('[data-fslightbox][data-href]')).forEach(anchor =>
    {
        anchor.setAttribute('href', anchor.dataset.href)
        anchor.removeAttribute('data-href');
    })
    if ('refreshFsLightbox' in window)
    {
        window.refreshFsLightbox()
    }
}

doc.addEventListener('DOMContentLoaded', setup)