const doc = document
const win = window

const video_module = import('video-worker/src/video-worker')

const prefersReducedMotion = matchMedia('(prefers-reduced-motion)');

doc.addEventListener('DOMContentLoaded', function ()
{
    const iframes = []
        .concat(...doc.querySelectorAll('.DestimotusOembedHoverEffect iframe[src]'))
        .concat(...doc.querySelectorAll('.video-with-custom-controls iframe[src]'))

    iframes.forEach(async iframe =>
    {
        let url = iframe.getAttribute('src')
        if (iframe.hasAttribute('data-src'))
        {
            url = iframe.getAttribute('data-src');
        }
        let hoverEffectDiv = iframe.closest('.DestimotusOembedHoverEffect');
        let customControlsDiv = iframe.closest('.video-with-custom-controls');
        let div = hoverEffectDiv ?? customControlsDiv

        if (!div)
        {
            console.log('no div ???', {hoverEffectDiv, customControlsDiv});
            return;
        }

        let options = {
            // autoplay: false,
            // loop: false,
            // mute: false,
            // volume: 100,
            // showControls: true,
            // accessibilityHidden: false,

            // start / end video time in seconds
            startTime: Number(div.dataset.startTime ?? 0),
            // endTime: 0,
        }
        if (hoverEffectDiv)
        {
            options.mute = true
            options.showControls = false
            options.loop = true
        }

        // console.log({iframe, url, hoverEffectDiv, customControlsDiv, options})

        const {default: VideoWorker} = await video_module;

        let video = new VideoWorker(url, options)
        // console.log(video);

        if (!video.isValid())
        {
            // console.error('video url invalid', {url, video})
            return;
        }

        let spinner
        let hovering
        let ready

        video.getVideo(new_elem =>
        {
            // move the iframe
            const hidden_div = new_elem.parentNode;
            const hidden_div_parent = hidden_div.parentNode;

            if (hidden_div_parent !== doc.body)
            {
                console.warn('hidden_div is not a child of body', {hidden_div, hidden_div_parent});
                return;
            }
            iframe.after(new_elem)
            // delete the hidden div and the original iframe
            iframe.parentNode.removeChild(iframe)
            iframe = new_elem
            hidden_div_parent.removeChild(hidden_div)


            spinner = doc.createElement('div');
            spinner.classList = 'DestimotusOembedHoverEffect__spinner';
            iframe.parentNode.appendChild(spinner);
            // iframe.after(spinner);
            iframe.classList.add(video.type)

            const playButton = customControlsDiv ? customControlsDiv.querySelector('button.play') : null;
            if (playButton)
            {
                if (video.type === 'vimeo' && customControlsDiv)
                {
                    playButton.classList.add('clickthrough')
                }
                playButton.addEventListener('click', () =>
                {
                    // console.log('click', {playButton})
                    video.play();
                })
            }

            if (hoverEffectDiv)
            {
                hoverEffectDiv.addEventListener('mouseover', () =>
                {
                    if (prefersReducedMotion.matches)
                    {
                        // console.log('preventing hover effect: prefers reduced motion')
                        return;
                    }

                    hovering = true;
                    if (!ready)
                    {
                        // console.log('stopping play: not ready yet!', {iframe})
                        return;
                    }
                    // console.log('adding mouseover class', {video})
                    hoverEffectDiv.classList.add('mouseover')
                    // player.playVideo();
                    video.play(options.startTime)
                })
                hoverEffectDiv.addEventListener('mouseout', () =>
                {
                    if (hovering)
                    {
                        // console.log('removing mouseover class')
                        hovering = false;
                        hoverEffectDiv.classList.remove('mouseover');
                        // setStartTime()
                    }
                })
                iframe.addEventListener('transitionend', () =>
                {
                    // console.log('transitionend', {video, iframe})
                    if (!hovering && ready)
                    {
                        video.pause()
                    }
                })
            }

            video.on('ready', e =>
            {
                ready = true;
            })
            video.on('started', e =>
            {
                // console.log('started', {e, video})
                iframe.setAttribute('data-state', 'STARTED')
            })

            // video.on('volumechange', e => console.log('volumechange', e))
            video.on('timeupdate', () => iframe.setAttribute('data-state', 'PLAYING'))
            video.on('play', () => iframe.setAttribute('data-state', 'PLAY'))
            video.on('pause', () => iframe.setAttribute('data-state', 'PAUSE'))
            video.on('error', e =>
            {
                // iframe.setAttribute('data-state', 'ERROR')
                console.error({video, e})
            })

            setInterval(function ()
            {
                div.classList.toggle('playing', iframe.dataset.state === 'PLAYING')
            }, 500)
        })
    })
})