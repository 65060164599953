const win = window
const requestAnimationFrame = win.requestAnimationFrame || window.requestIdleCallback || (callback => win.setTimeout(callback, 1000 / 24))

export default function raf(fn, throttle)
{
    let isRunning
    let that
    let args

    const run = function ()
    {
        isRunning = false
        fn.apply(that, args)
    }

    return function ()
    {
        that = this
        args = arguments

        if (isRunning && throttle)
        {
            return
        }

        isRunning = true
        requestAnimationFrame(run)
    }
}