const doc = document
const updatePlayingStatus = e =>
{
    let media = e.target;
    ['paused', 'ended'].forEach(prop =>
    {
        media.classList.toggle(prop, media[prop]);
        media.classList.toggle(`not-${prop}`, !media[prop]);
    });

    let not_playing = media.paused || media.ended;
    media.classList.toggle('playing', !not_playing);
    // media.classList.toggle('not-playing', not_playing);

    media.classList.toggle('at-begin', not_playing && !media.currentTime)
}
['play', 'playing', 'pause', 'ended']
    .forEach(event_name => doc.addEventListener(event_name, updatePlayingStatus, {capture: true}))
doc.addEventListener('DOMContentLoaded', () => Array.from(doc.querySelectorAll('audio,video'))
    .forEach(target => updatePlayingStatus({target})))