import scrollToElement from "./scrollToElement";

const doc = document

doc.addEventListener('DOMContentLoaded', () =>
{
    const btn = doc.getElementById('scroll-viewport')
    const main = doc.getElementById('site-main')
    if (btn && main)
    {
        btn.addEventListener('click', e =>
        {
            e.preventDefault()
            scrollToElement(main)
        })
    }
})