/**
 * https://stackoverflow.com/questions/27745438/how-to-compute-getboundingclientrect-without-considering-transforms
 * @param {HTMLElement} el
 * @returns {{top: number, left: number, bottom: *, x: number, width: (number|number), y: number, right: *, height: (number|number)}|*}
 */
export default function adjustedBoundingRect(el)
{
    let rect = el.getBoundingClientRect();
    let style = getComputedStyle(el);
    let tx = style.transform;

    if (tx)
    {
        let sx, sy, dx, dy;
        if (tx.startsWith('matrix3d('))
        {
            const ta = tx.slice(9, -1).split(/,\s*/);
            sx = +ta[0];
            sy = +ta[5];
            dx = +ta[12];
            dy = +ta[13];
        } else if (tx.startsWith('matrix('))
        {
            const ta = tx.slice(7, -1).split(/,\s*/);
            sx = +ta[0];
            sy = +ta[3];
            dx = +ta[4];
            dy = +ta[5];
        } else
        {
            return rect;
        }

        const to = style.transformOrigin;
        const x = rect.x - dx - (1 - sx) * parseFloat(to);
        const y = rect.y - dy - (1 - sy) * parseFloat(to.slice(to.indexOf(' ') + 1));
        const width = sx ? rect.width / sx : el.offsetWidth;
        const height = sy ? rect.height / sy : el.offsetHeight;
        return {
            x: x,
            y: y,
            width: width,
            height: height,
            top: y,
            right: x + width,
            bottom: y + height,
            left: x,
            original: rect
        };
    } else
    {
        return rect;
    }
}
