const doc = document;

const container_class = 'DestimotusFaq'
const active_class = 'DestimotusFaq--active';
const animating_class = 'DestimotusFaq--animating';

import {toggle} from "slide-element";

doc.addEventListener('click', async e =>
{
    const dt = e.target.closest(`.${container_class}>dt`)

    if (dt)
    {
        e.preventDefault()
        const cls = dt.classList
        cls.toggle(active_class)

        const dd = dt.nextElementSibling
        dd.classList.add(animating_class)
        await toggle(dd, {
            easing: 'ease-in-out',
            duration: 350,
        });
        dd.classList.remove(animating_class)
    }
}, {capture: true})