import raf from "./raf"

const win = window
const doc = document
const html = doc.querySelector('html')
const html_style = html.style
const body = doc.documentElement || doc.body

const detect = raf(() =>
{
    const client_width = body.clientWidth
    html_style.setProperty('--scrollbar-width', win.innerWidth - client_width + 'px')
    html_style.setProperty('--client-width', client_width + 'px')
})

detect()

doc.addEventListener('DOMContentLoaded', detect)
win.addEventListener('resize', detect)
win.addEventListener('orientationchange', detect)