import adjustedBoundingRect from "./adjustedBoundingRect";
import rectsDoNotCollide from "./rectsDoNotCollide";

const viewport = document.getElementById('viewport')

export default function isInViewport(el)
{
    const rect1 = adjustedBoundingRect(viewport)
    const rect2 = adjustedBoundingRect(el)
    return !rectsDoNotCollide(rect1, rect2)
}

window.isInViewport = isInViewport;