import html_class from "./html_class";
import raf from "./raf";

import "bootstrap/js/src/collapse";

const doc = document;
const win = window

let last_scroll_y = 0;

const smaller_class = 'topbar-is-smaller'
const not_smaller_class = 'topbar-not-smaller';

const topbar = doc.getElementById('topbar');
const topbar_mobile = doc.getElementById('topbar-mobile');
const topbar_mobile_toggle = doc.getElementById('topbar-mobile-toggle')

function updateTopbarClass()
{
    let is_smaller = false;
    const scroll_y = win.scrollY;
    const topbar_height = topbar.clientHeight;
    const topbar_intersecting = scroll_y < topbar_height;

    if (win.innerWidth < 1200)
    {
        is_smaller = true;
    } else if (!topbar_intersecting)
    {
        is_smaller = scroll_y > last_scroll_y;
    }

    html_class.toggle(smaller_class, is_smaller);
    html_class.toggle(not_smaller_class, !is_smaller);


    html_class.toggle('at-top', scroll_y < 1);
    html_class.toggle('not-top', scroll_y > 0);

    last_scroll_y = scroll_y;
}


function updateMobileMenuClass()
{
    html_class.toggle('mobile-menu-shown', topbar_mobile.classList.contains('show'));
}

doc.addEventListener('show.bs.collapse', e =>
{
    if (e.target.matches('.level-1'))
    {
        closeTopbarCollapsibles();
    }
})

doc.addEventListener('shown.bs.collapse', updateMobileMenuClass)
doc.addEventListener('hidden.bs.collapse', updateMobileMenuClass)

function closeTopbarCollapsibles()
{
    // close the collapsibles
    Array.from(topbar.querySelectorAll('.menu-dropdown-close.level-1[data-bs-toggle=collapse][aria-expanded=true]'))
        .forEach(toggle =>
        {
            toggle.click()
            toggle.blur();
        })
}

function onscroll()
{
    topbar_mobile.classList.remove('show');
    updateMobileMenuClass();
    updateTopbarClass()
    closeTopbarCollapsibles();
}

updateTopbarClass();
win.addEventListener('scroll', raf(onscroll), {passive: true});
win.addEventListener('resize', raf(updateTopbarClass), {passive: true});
doc.addEventListener('DOMContentLoaded', updateTopbarClass);

function toggleTopbarMobileVisibility()
{
    topbar_mobile.classList.toggle('show');
    topbar_mobile_toggle.setAttribute('aria-expanded', topbar_mobile.classList.contains('show') ? 'true' : 'false')
    updateMobileMenuClass()
}

doc.addEventListener('click', e =>
{
    let toggle = e.target.closest('#topbar-mobile-toggle')
    if (toggle)
    {
        e.preventDefault()
        toggleTopbarMobileVisibility()
    }
}, {capture: true})
// doc.addEventListener()