import raf from "./raf";
// import adjustedBoundingRect from "./adjustedBoundingRect";
import {setupCustomVideoControls} from "./video_controls";
import isInViewport from "./isInViewport";

const doc = document
const win = window

const CLS = 'in-viewport'
const ATTR = 'data-in-viewport'
const ATTR_CONTENT = 'data-in-viewport-content'

// const viewport = doc.getElementById('viewport')

// make elements animate in using a group
function groupViewportElements(selectors, group, anim = 'fade slide-up')
{
    group = group ?? selectors
    Array.from(doc.querySelectorAll(`:where(${selectors}):not([${ATTR}])`)).forEach(el =>
    {
        el.setAttribute(ATTR, anim)
        el.setAttribute('data-viewport-group', group)
    })
}

const update = raf(() =>
{
    // const rect1 = adjustedBoundingRect(viewport)

    let group_counters = {};
    Array.from(doc.querySelectorAll(`[${ATTR}]:not(.${CLS}), [${ATTR_CONTENT}]:not(.${CLS})`))
        .forEach(el =>
        {
            // const rect2 = adjustedBoundingRect(el)
            if (isInViewport(el))
            {
                const dataset = el.dataset
                el.classList.add(CLS)

                if ('viewportGroup' in dataset)
                {
                    const group = dataset.viewportGroup;
                    if (group in group_counters)
                    {
                        el.style.setProperty('--viewport-group-queue-index', group_counters[group]);
                        group_counters[group]++;
                    } else
                    {
                        group_counters[group] = 1;
                    }
                }

                if ('inViewportContent' in dataset)
                {
                    // replace HTML of element if inside viewport
                    el.innerHTML = dataset.inViewportContent

                    setupCustomVideoControls()
                }
            }
        })

    // console.log('group_counters', group_counters)
})

update()
doc.querySelector('html').classList.add('in-viewport-animations')

doc.addEventListener('DOMContentLoaded', () =>
{
    groupViewportElements('.wpforms-submit, .wpforms-field')

    update()
})
// doc.addEventListener('DOMContentLoaded', update)
win.addEventListener('scroll', update)
win.addEventListener('resize', update)
win.addEventListener('orientationchange', update)
win.addEventListener('deviceorientation', update)

doc.addEventListener('click', update, {capture: true})
doc.addEventListener('transitionend', update, {capture: true})
doc.addEventListener('animationend', update, {capture: true})
