import adjustedBoundingRect from "./adjustedBoundingRect";

export default function scrollToElement(element, offset = 140)
{
    const bodyRect = adjustedBoundingRect(document.body).top;
    const elementRect = adjustedBoundingRect(element).top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
    });
}

window.scrollToElement = scrollToElement;

// wpforms
/*
document.addEventListener('DOMContentLoaded', () =>
{
    wpforms.scrollToError = function (el)
    {
        if (!isInViewport(el))
        {
            scrollToElement(el)
        }
    };
    // window.wpforms_pageScroll = false
});
 */