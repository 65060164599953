const mixitup_promise = import('mixitup')

export default async function setup()
{
    const elements = Array.from(document.querySelectorAll('.DestimotusStory__list[id]:not(.mixitup-added)'))
    for (const list of elements)
    {
        const module = await mixitup_promise
        const mixitup = module.default
        new mixitup(list, {
            selectors: {
                target: '[role=listitem], [data-ref=gap]'
            }
        })
        list.classList.add('mixitup-added')

    }

}

setup();
document.addEventListener('DOMContentLoaded', setup)