const doc = document

function setup()
{
    const videos = Array.from(doc.querySelectorAll('.DestimotusEmployee__list video:not(.destimotusteam--events-added)'))
    videos.forEach(video =>
    {
        video.classList.add('destimotusteam--events-added')
        video.addEventListener('play', () =>
        {
            Array.from(
                video.closest('.DestimotusEmployee__list')
                    .querySelectorAll('video')
            )
                .forEach(other_video =>
                {
                    if (other_video !== video)
                    {
                        other_video.pause()
                    }
                })

        })
    })
}

setup();