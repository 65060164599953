const promises = {};
const doc = document;
export default function loadCSSFile(url)
{
    if (url in promises)
    {
        return promises[url];
    }
    promises[url] = new Promise((resolve, reject) =>
    {
        const link = doc.createElement('link');
        link.href = url;
        link.rel = 'stylesheet';
        link.media = 'print';
        link.onload = () =>
        {
            link.media = 'all';
            resolve();
        }
        link.onerror = (e) =>
        {
            reject(e);
        }
        doc.querySelector('head').appendChild(link);
    });
    return promises[url];
}