// based on https://stackoverflow.com/questions/20223243/js-get-percentage-of-an-element-in-viewport

import raf from "./raf"

const win = window
const doc = document

const run = raf(() =>
{
    const
        elements = Array.from(doc.querySelectorAll('[data-parallax]')),
        {innerHeight, scrollY} = win

    elements.forEach(element =>
    {
        const
            {offsetTop, offsetHeight} = element,
            distance = (scrollY + innerHeight) - offsetTop,
            percentage = Math.round(distance / ((innerHeight + offsetHeight) / 100))

        element.style.setProperty('--parallax', Math.min(100, Math.max(0, percentage)) + '%')
    })
})

run()

doc.addEventListener('DOMContentLoaded', run)
win.addEventListener('resize', run, {passive: true})
win.addEventListener('scroll', run, {passive: true})
win.addEventListener('orientationchange', run)