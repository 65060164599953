const doc = document;

export function getCarouselsToRun()
{
    return Array.from(doc.querySelectorAll('[data-bs-ride=carousel]'))
}

export function setupCarouselScript()
{
    if (getCarouselsToRun().length)
    {
        import('bootstrap/js/src/carousel').then(CarouselModule =>
        {
            const Carousel = CarouselModule.default;
            getCarouselsToRun().forEach(el => Carousel.getOrCreateInstance(el))
        })
    }
}

setupCarouselScript()
doc.addEventListener('DOMContentLoaded', setupCarouselScript)