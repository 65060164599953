// import {requestFullscreen} from "./fullscreen";

import {getElementFromSelector} from "bootstrap/js/src/util";

const doc = document
const video_worker_promise = import('video-worker')
const play_svg_url = new URL('../img/play.svg', import.meta.url)

const setup = async () =>
{
    const banner_oembed_wrapper = doc.getElementById('banner_oembed_wrapper')
    if (!banner_oembed_wrapper)
    {
        return
    }
    const url = banner_oembed_wrapper.dataset.url
    if (!url)
    {
        return
    }

    const module = await video_worker_promise
    const VideoWorker = module.default

    const video_background = new VideoWorker(url, {
        autoplay: true,
        loop: true,
        showControls: false,
        accessibilityHidden: true,
        mute: true
    })

    video_background.getVideo((video) =>
    {
        video.classList.add('youtube_notouch');
        const style = video.style
        style.setProperty('pointer-events', 'none')
        style.setProperty('height', '200vw')
        style.setProperty('top', '50%')
        style.setProperty('transform', 'translateY(-50%)')

        style.setProperty('opacity', 0)

        const $parent = video.parentNode
        banner_oembed_wrapper.insertAdjacentElement('afterbegin', video)
        $parent.parentNode.removeChild($parent)

        const onStarted = () =>
        {
            console.log('video_background started', {banner_oembed_wrapper, video_background, btn})
            video_background.off('started', onStarted)
            if (video.animate)
            {
                video.animate([
                    {opacity: 0},
                    {opacity: 1},
                ], {
                    duration: 1500,
                    iterations: 1
                }).ready.then(() =>
                {
                    style.setProperty('opacity', 1)
                })
            } else
            {
                style.setProperty('opacity', 1)
            }
        }

        video_background.on('started', onStarted)
    })

    const video_normal = new VideoWorker(url)
    video_normal.getVideo((video) =>
    {
        video.classList.add('youtube_notouch');
        video.style.pointerEvents = 'none'

        // otherwise .play() doesnt work!
        let allow
        if (video.hasAttribute('allow'))
        {
            allow = video.hasAttribute('allow') + '; autoplay';
        } else
        {
            allow = 'autoplay';
        }
        video.setAttribute('allow', allow)

        const style = video.style

        style.opacity = 0


        const $parent = video.parentNode
        banner_oembed_wrapper.appendChild(video)
        $parent.parentNode.removeChild($parent)

        const img = new Image()
        img.setAttribute('style', 'min-width:45px;width:5vw;max-width:90px;height:auto;')
        img.src = play_svg_url

        const play_btn = doc.createElement('button')
        play_btn.setAttribute('style', 'position:absolute;top:50%;left:50%;background:transparent;border:0;transform:translate(-50%,-50%);cursor:pointer')
        play_btn.appendChild(img)

        play_btn.addEventListener('click', () =>
        {
            video.style.pointerEvents = '';
            console.log('clicked play_btn')

            const onShown = () =>
            {
                console.log('show done')
                style.opacity = 1

                // const scroll_viewport = doc.getElementById('scroll-viewport')
                // if (scroll_viewport)
                // {
                //     scroll_viewport.style.display = 'none'
                // }
                // requestFullscreen(video)
                try
                {
                    video_normal.play()
                } catch (e)
                {
                    console.log(video_normal, e)
                }
            }

            if (video.animate)
            {
                style.opacity = 0
                video.animate([
                    {opacity: 0},
                    {opacity: 1},
                ], {
                    duration: 500,
                    iterations: 1
                }).ready.then(onShown)
                play_btn.animate([
                    {opacity: 1},
                    {opacity: 0},
                ], {
                    duration: 500,
                    iterations: 1
                }).ready.then(() => play_btn.style.display = 'none')
            } else
            {
                play_btn.style.display = 'none'
            }
        })

        banner_oembed_wrapper.appendChild(play_btn)

        const scroll_viewport = doc.getElementById('scroll-viewport')
        if (scroll_viewport)
        {
            banner_oembed_wrapper.removeChild(scroll_viewport)
            banner_oembed_wrapper.appendChild(scroll_viewport)
        }
    })

    const scroll_viewport = doc.getElementById('scroll-viewport')
    if (scroll_viewport)
    {
        banner_oembed_wrapper.removeChild(scroll_viewport)
        banner_oembed_wrapper.appendChild(scroll_viewport)
    }

    // console.log({video_background, video_normal})
}

setup()
// doc.addEventListener('DOMContentLoaded', setup)
